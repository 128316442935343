
import { defineComponent } from "vue";
import list from "@/components/common/list.vue";
import Follow from "@/components/follow/Follow.vue";
export default defineComponent({
  name: "Attention",
  components: {
    list,
    Follow,
  },
});
